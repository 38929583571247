/* Custom Stylesheet */
/**
 * Use this file to override Materialize files so you can update
 * the core Materialize files in the future
 *
 * Made By MaterializeCSS.com
 */

nav ul a,
nav .brand-logo {
  color: #444;
  height: 20;
}

p {
  line-height: 2rem;
}

.button-collapse {
  color: #26a69a;
}

.parallax-container {
  min-height: 380px;
  line-height: 0;
  height: auto;
  color: rgba(255, 255, 255, 0.9);
}
.parallax-container .section {
  width: 100%;
}

@media only screen and (max-width: 992px) {
  .parallax-container .section {
    position: absolute;
    top: 40%;
  }
  #index-banner .section {
    top: 10%;
  }
}

@media only screen and (max-width: 600px) {
  #index-banner .section {
    top: 0;
  }
}

.icon-block {
  padding: 0 15px;
}
.icon-block .material-icons {
  font-size: inherit;
}

footer.page-footer {
  margin: 0;
}
.singleItem {
  margin: 5px;
  padding: 5px;
  text-align: center;
  border: inherit;
  color: #444;
  font-size: inherit;
}
.listing-item {
  margin: 0 0 20px 0;
  border: 1px solid #f1f1f1;
  position: relative;
  overflow: hidden;
}

.listing-item-body {
  padding: 20px 15px 15px;
  -webkit-transition: ease-in-out all 0.25s;
  -o-transition: ease-in-out all 0.25s;
  transition: ease-in-out all 0.25s;
}

.listing-item:hover .listing-item-body {
  background-color: #fafafa;
}

.listing-item h3 {
  font-size: 18px;
}

.listing-item p {
  color: #999999;
  font-size: 13px;
}

.listing-item del,
.price del {
  font-size: 13px;
  line-height: 1;
  color: #cccccc;
}

.elements-list > * {
  border-right: 1px solid #dddddd;
  margin: 5px 6px 5px 0;
  padding-right: 10px;
  line-height: 24px;
  height: 22px;
  display: inline-block;
}

.elements-list > *:last-child {
  border-right: none;
  margin-right: 0;
  padding-right: 0;
}

.price {
  font-size: 22px;
  color: #000000;
  font-weight: 300;
}

.wishlist:hover i:before {
  content: "\f004";
}

.listing-item .badge {
  border-radius: 0;
  position: absolute;
  border-color: transparent;
  top: -1px;
  left: -1px;
  font-weight: 400;
}

.sorting-filters {
  background-color: #f3f3f3;
  border: 1px solid #dddddd;
  padding: 20px 15px;
  margin-bottom: 40px;
}

.sidebar .sorting-filters {
  margin-bottom: 0;
}

.sorting-filters label {
  display: block;
  margin-bottom: 5px;
  font-size: 14px;
}

.sorting-filters form {
  margin: 0;
}

.sorting-filters .btn {
  margin: 20px 0 0;
  min-width: 0;
  width: 100%;
}

.sorting-filters .form-control,
.sorting-filters .form-control:focus {
  margin-bottom: 5px;
  -webkit-box-shadow: inset 0 2px 3px rgba(0, 0, 0, 0.12);
  box-shadow: inset 0 2px 3px rgba(0, 0, 0, 0.12);
  border-color: #f3f3f3;
}

.sorting-filters .form-inline .form-control {
  width: 100%;
}

.list-with-image {
  margin-bottom: 20px;
  border-bottom: 1px solid #f1f1f1;
  padding-bottom: 5px;
}

.list-with-image:last-child {
  border-bottom: none;
}

.list-with-image .overlay-container {
  float: left;
  width: 80px;
  margin-bottom: 10px;
}

.list-with-image h2,
.list-with-image p {
  margin-left: 100px;
}

.list-with-image h2 {
  font-size: 16px;
  margin-top: 0;
  margin-bottom: 5px;
}

.list-with-image p {
  font-size: 13px;
  color: #999;
  margin-bottom: 15px;
}

.shop-banner {
  background-color: transparent;
  border-color: transparent;
}

.shop-banner .tp-bullets {
  display: none !important;
}

.shop-banner .sorting-filters {
  margin-bottom: 0;
}

/* Small devices (tablets, 768px and up) */
@media (min-width: 768px) {
  .sorting-filters .form-inline .form-group {
    width: 18%;
    margin-right: 1.9%;
  }

  .sorting-filters .form-inline .form-group:last-child {
    margin-right: 0;
  }

  .shop-banner {
    position: relative;
  }

  .shop-banner .sorting-filters {
    position: absolute;
    bottom: 20px;
    z-index: 29;
    width: 100%;
    background-color: rgba(255, 255, 255, 0.7);
    border: none;
  }

  .shop-banner .sorting-filters * {
    color: #000000;
  }

  .shop-banner .sorting-filters .form-control,
  .shop-banner .sorting-filters .form-control:focus {
    border-color: #aaaaaa;
  }
}

/* Cart and Checkout pages
---------------------------------- */
.cart.table .price {
  width: 18%;
}

.cart.table .amount,
.cart.table .remove,
.cart.table .quantity {
  width: 12%;
}

.table .information {
  width: 80%;
}

.cart.table .quantity input {
  width: 60px;
  padding: 6px;
}

.cart.table .product {
  font-size: 15px;
}

.cart.table .amount {
  text-align: right;
}

.cart .price {
  font-size: 14px;
}

.cart.table .product small {
  font-size: 12px;
  display: block;
  color: #999;
}

.cart.table thead > tr > th {
  font-size: 16px;
}

.cart.table .total-quantity,
.cart.table .total-amount {
  padding-top: 15px;
  padding-bottom: 15px;
  font-size: 16px;
  font-weight: 700;
}

.cart.table .total-amount {
  text-align: right;
}

/* Small devices (tablets, phones less than 767px) */
@media (max-width: 767px) {
  .cart.table thead > tr > th {
    font-size: 14px;
  }

  .cart.table .amount,
  .cart.table .price {
    width: 12%;
  }

  .cart.table .remove,
  .cart.table .quantity {
    width: 8%;
  }

  .cart.table > thead > tr > th,
  .cart.table > tbody > tr > th,
  .cart.table > tfoot > tr > th,
  .cart.table > thead > tr > td,
  .cart.table > tbody > tr > td,
  .cart.table > tfoot > tr > td {
    padding: 8px 10px;
  }
}

/* Extra small devices (phones, less than 480px) */
@media (max-width: 480px) {
  .cart.table > thead > tr > th,
  .cart.table > tbody > tr > th,
  .cart.table > tfoot > tr > th,
  .cart.table > thead > tr > td,
  .cart.table > tbody > tr > td,
  .cart.table > tfoot > tr > td {
    padding: 8px 7px;
  }
}
.overlay-container {
  height: 200px;
  text-align: center;
  display: inline-block;
}
.product-image {
  text-align: center;
  display: inline-block;
  padding: 20px 5px 5px 5px;
  max-width: 200;
  height: 118;
}
fixed-position {
  position: fixed;
}
.list-with-image {
  margin-bottom: 20px;
  border-bottom: 1px solid #f1f1f1;
  padding-bottom: 5px;
}

.list-with-image:last-child {
  border-bottom: none;
}

.list-with-image .overlay-container {
  float: left;
  width: 80px;
  margin-bottom: 10px;
}

.list-with-image h2,
.list-with-image p {
  margin-left: 100px;
}

.list-with-image h2 {
  font-size: 16px;
  margin-top: 0;
  margin-bottom: 5px;
}

.list-with-image p {
  font-size: 13px;
  color: #999;
  margin-bottom: 15px;
}
.box-style-2 {
  margin-top: 20px;
}

.box-style-2 i {
  font-size: 36px;
  line-height: 60px;
}

.box-style-2 .icon-container {
  float: left;
  width: 60px;
  height: 60px;
  text-align: center;
  background-color: #ffffff;
  border: 1px solid #666666;
  -webkit-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}

.box-style-2 .body {
  margin-left: 80px;
}

.box-style-2 h2 {
  font-size: 24px;
  margin-top: 0;
}

.box-style-2:hover .icon-container {
  border: 1px solid #666666;
}

.box-style-2:hover .default-bg {
  background-color: #ffffff;
  border: 1px solid #e84c3d;
}

.box-style-2:hover .default-bg * {
  color: #e84c3d;
}

.box-style-2:hover .dark-bg {
  background-color: #ffffff;
  border: 1px solid #666666;
}

.box-style-2:hover .dark-bg * {
  color: #666666;
}

.box-style-2:hover .white-bg * {
  color: #e84c3d;
}
.support-item {
  border: 2px solid #444;
  margin: 15px;
}

.service-item {
  border: 1px solid #444;
  padding-top: 0px;
  padding: 15px;
  margin-right: 20px;
  height: 200px;
  font-size: 16px;
  font-weight: 300;
}

.form-background {
  background: url(../img/banner-bg22.jpg) no-repeat center center;
}
