body,
html {
  width: 100%;
  height: 100%;
}
body,
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Lato", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 700;
}
.topnav {
  font-size: 16px;
}
.toplogo {
  padding-bottom: 5px;
  padding-top: 5px;
  padding-left: 1px;
  background-image: url(../img/lamunga.png);
}
.lead {
  font-size: 16px;
  font-weight: 300;
}
.intro-header {
  padding-top: 1px;
  padding-bottom: 0px;
  text-align: center;
  color: #f8f8f8;
  background: url(../img/consult.jpg) no-repeat center center;
  background-size: cover;
}
.intro-message {
  position: relative;
  padding-top: 2%;
  padding-bottom: 5%;
}
.intro-message > h1 {
  margin: 0;
  text-shadow: 2px 2px 3px rgba(0, 0, 0, 0.6);
  font-size: 4em;
}
.intro-message > h2 {
  margin: 0;
  text-shadow: 2px 2px 3px rgba(0, 0, 0, 0.6);
  font-size: 3em;
}
.intro-divider {
  width: 400px;
  border-top: 1px solid #f8f8f8;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
}
.intro-message > h3 {
  text-shadow: 2px 2px 3px rgba(0, 0, 0, 0.6);
}
@media (max-width: 767px) {
  .intro-message {
    padding-bottom: 5%;
  }
  .intro-message > h1 {
    font-size: 2em;
  }
  ul.intro-social-buttons > li {
    display: block;
    margin-bottom: 20px;
    padding: 0;
  }
  ul.intro-social-buttons > li:last-child {
    margin-bottom: 0;
  }
  .intro-divider {
    width: 100%;
  }
}
.network-name {
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 2px;
}
.content-section-a {
  padding: 20px 0px;
  background-color: #f8f8f8;
}
.content-section-b {
  padding: 20px 0px;
}
.section-heading {
  margin-bottom: 10px;
}
.section-heading-spacer {
  float: left;
  width: 200px;
  border-top: 3px solid #e7e7e7;
}
.banner {
  padding: 100px 0;
  color: #f8f8f8;
  background: url(../img/banner-bg.jpg) no-repeat center center;
  background-size: cover;
}
.banner h2 {
  margin: 0;
  text-shadow: 2px 2px 3px rgba(0, 0, 0, 0.6);
  font-size: 3em;
}
.banner ul {
  margin-bottom: 0;
}
.banner-social-buttons {
  float: right;
  margin-top: 0;
}
@media (max-width: 1199px) {
  ul.banner-social-buttons {
    float: left;
    margin-top: 15px;
  }
}
@media (max-width: 767px) {
  .banner h2 {
    margin: 0;
    text-shadow: 2px 2px 3px rgba(0, 0, 0, 0.6);
    font-size: 3em;
  }
  ul.banner-social-buttons > li {
    display: block;
    margin-bottom: 20px;
    padding: 0;
  }
  ul.banner-social-buttons > li:last-child {
    margin-bottom: 0;
  }
}
footer {
  padding: 20px 20px 0 20px;
  width: 100%;
}
p.copyright {
  margin: 0px 0 0;
}
.pagination {
  text-align: center;
  padding: 20px;
}
.shop-p {
  white-space: nowrap;

  overflow: auto;
}

.filter-panel {
  margin-left: 10px;
  margin-right: inherit;
}
.product-box {
  padding-top: 5px;
}
.shop-ps {
  white-space: nowrap;
  width: 190px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.product-card {
  width: 18rem;
}
.map-section {
  width: 95%;
  height: 230px;
}
